import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "../css/header.css";

function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("scrolling", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`header-container ${scrolling ? "scrolled" : ""}`}>
      {!isMobile ? (
        <Menu  className={`custom-menu ${scrolling ? "scrolled" : ""}`} mode="horizontal">
          <div className={`menu-left-web ${scrolling ? "scrolled" : ""}`}>
            <img
              src="/logo/logo.ico"
              alt="No fame Logo"
              className={scrolling ? "logo small" : "logo"}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
          </div>

          <div className="menu-center-group"></div>

          <div className="menu-right-web">
            <Menu.Item key="contact">
              <Link to="/contact">contact</Link>
            </Menu.Item>
          </div>
        </Menu>
      ) : (
        <>
          <div className="custom-menu-phone">
            <div className="logo-mobile">
              <img src="/logo/logo.ico" alt="Logo" width={40} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
            </div>

            <div className="menu-right-phone">
              <Link to="/contact">contact</Link>
            </div>
          </div>
        </>
      )}
    </div>

  );

}

export default Header;
