import React, { useEffect, useState, useRef } from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { Modal } from "antd";

const videos = [
    {
        src: "vimeo", // ระบุว่าอันนี้จะใช้ iframe
        vimeoId: "929781926", // Vimeo ID
        description: "Amazing Thailand",
        thumbnails: "/home/videology/thumbnails/2.png",
    },
    { src: "/home/videology/neon_countdown.mp4", description: "Music Festival", thumbnails: "/home/videology/thumbnails/4.png"  },
    { src: "/home/videology/s2o.mp4", description: "S2O Bangkok Music Festival", thumbnails: "/home/videology/thumbnails/3.png"  },
    { src: "/home/videology/j_boroski.mp4", description: "J.Boroski - Bangkok", thumbnails: "/home/videology/thumbnails/5.png"  },
    { src: "/home/videology/caribana_festival.mp4", description: "Caribana Festival", thumbnails: "/home/videology/thumbnails/YB_thumbnail.png"  },
];

function Video() {
    const [thumbnails, setThumbnails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const [currentDes, setCurrentDes] = useState("");
    const videoRef = useRef(null);

    const pageVariants = {
        initial: { y: "100%", opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
    };

    const handleOpenModal = (videoSrc, descriptionSrc) => {
        setCurrentVideo(videoSrc);
        setCurrentDes(descriptionSrc);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        if (currentVideo.src !== "vimeo" && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
        setCurrentDes("");
        setCurrentVideo("");
        setIsModalOpen(false);
    };
    

    useEffect(() => {
        videos.forEach((video) => {
            const videoElement = document.createElement("video");
            videoElement.src = video.src;
            videoElement.crossOrigin = "anonymous";
            videoElement.load();
            videoElement.onloadedmetadata = () => {
                videoElement.currentTime = videoElement.duration / 2.2;
            };
            videoElement.onseeked = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                setThumbnails((prev) => ({
                    ...prev,
                    [video.src]: canvas.toDataURL("image/png"),
                }));
            };
        });
    }, []);

    return (
        <motion.div
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="video-container"
        >
            <Link to="/">
                <img src="/logo/icon_arrow.ico" width={40} />
            </Link>
            <div className="playlist-header">
                <h1>Videography</h1>
                <p>Commercial • Event • Travel • Creative Concepts & Styling  </p>
            </div>

            <div className="video-grid">
                {videos.map((data, index) => (
                    <div key={index} className="video-item">
                        <img
                            src={data.thumbnails}
                            alt="Video Thumbnail"
                            className="video-thumbnail"
                            onClick={() => handleOpenModal(data)}
                        />
                        <div className="video-description">
                            <p>{data.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            {currentVideo && (
    <Modal
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
        width="100vw"
        centered
        className="modal"
    >
        <h2>{currentVideo.description}</h2>

        {currentVideo.src === "vimeo" ? (
            <div style={{ padding: "42.19% 0 0 0", position: "relative" }}>
                <iframe
                    src={`https://player.vimeo.com/video/${currentVideo.vimeoId}?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                    allowFullScreen
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                    title={currentVideo.description}
                ></iframe>
            </div>
        ) : (
            <video
                key={currentVideo.src}
                ref={videoRef}
                className="modal-video"
                controls
                autoPlay
            >
                <source src={currentVideo.src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        )}
    </Modal>
)}

        </motion.div>
    );
}

export default Video;
